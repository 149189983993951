import { createSlice } from "@reduxjs/toolkit";
import { selectBook } from "./librarySlice";

// value is the title of the submenu if exists for particular view/page
// null if no submenu for that page:
export const Pages = {
  ListView: "Contents",
  TilesView: "Insights",
  BookView: "Overview",
  Library: "Library",
  Feed: "Feed",
  Profile: null,
  MapView: "Mind Map",
};

export const CurrentPageTabSlice = createSlice({
  name: "CurrentPageTabData",
  initialState: {
    currentPageTab: null,
    showFilter: null,
  },
  reducers: {
    setCurrentPageTabData: (state, action) => {
      state.currentPageTab = action.payload;
      state.showFilter = false;
    },
    setShowFilter: (state, action) => {
      if (action.payload !== null) {
        state.showFilter = action.payload;
        if (pageHasFilter(state.currentPageTab)) {
          state.showFilter = false;
        }
      }
    },
  },
  extraReducers(builder) {
    // close filter when book is changed:
    builder.addCase(selectBook.fulfilled, (state) => {
      state.showFilter = false;
    });
  },
});

export const { setCurrentPageTabData, setShowFilter } =
  CurrentPageTabSlice.actions;

export default CurrentPageTabSlice.reducer;

export const pageHasFilter = (name) => {
  return (
    Object.values(Pages).filter((pageTab) => pageTab === name).length === 0
  );
};
