import React, { useState, useRef, useEffect } from "react";
import { Markmap } from "markmap-view";
import { Toolbar } from "markmap-toolbar";
import { Transformer } from "markmap-lib";
import "markmap-toolbar/dist/style.css";
import * as markmap from "markmap-view";
import { loadCSS, loadJS } from "markmap-common";
import { useDispatch, useSelector } from "react-redux";
import {
  createMarkmap,
  createMarkmapAdmin,
  getCurrentBook,
  updateBook,
} from "../../Utils/Features/librarySlice";
import { ApiStatus } from "../../Utils/Features/ApiStatus";
import { PrimaryButton } from "../../components/Common/Buttons/PrimaryButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  useMediaQuery,
} from "@mui/material";
import { paymentStatuses } from "../../components/Billing/subscriptionTypes";
import { Message } from "../../components/Common/Message";
import { AmazonSingleSync } from "../../components/Book/AmazonSingleSync";
import { SyncOrigin } from "../../components/Connectors/ChromeExtensionCommunication";

import { exampleMarkmap } from "./exampleMarkmap";

export const transformer = new Transformer();
const { scripts, styles } = transformer.getAssets();
loadCSS(styles);
loadJS(scripts, { getMarkmap: () => markmap });

function renderToolbar(mm, wrapper) {
  while (wrapper?.firstChild) wrapper.firstChild.remove();
  if (mm && wrapper) {
    const toolbar = new Toolbar();
    toolbar.attach(mm);
    wrapper.append(toolbar.render());
  }
}

export default function MapView() {
  const dispatch = useDispatch();

  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const openBook = useSelector((state) => getCurrentBook(state));
  const [markmap, setMarkmap] = useState(
    openBook && !openBook?.read_only ? exampleMarkmap : ""
  );

  const refSvg = useRef();
  const refMm = useRef();
  const refToolbar = useRef();

  const [justCreated, setJustCreated] = useState(false);
  const [editorOpen, setEditorOpen] = useState(false);

  const { markmapStatus } = useSelector((state) => state.library);
  const [creating, setCreating] = useState(false);

  const markmapContainerRef = useRef(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const { userData } = useSelector((state) => state.auth);

  const canCreateMarkmap =
    openBook &&
    !openBook?.read_only &&
    // admin can create markmaps anytime
    (userData.isAdmin ||
      // paid user can create markmap once per book
      ((userData.profile.paymentStatus === paymentStatuses.Paid ||
        userData.profile.paymentStatus === paymentStatuses.Trial) &&
        !openBook?.freeMarkmapCreated) ||
      // free user can create only one markmap
      (userData.profile.paymentStatus === paymentStatuses.Free &&
        !userData.profile.freeMarkmapCreated &&
        !openBook?.freeMarkmapCreated));

  useEffect(() => {
    const updateSvg = () => {
      if (refSvg.current) {
        // update all the text colors in svg:
        refSvg.current
          .querySelectorAll(".markmap-foreign div > div")
          .forEach((div) => {
            div.style.color = "var(--fontDarkColor)";
          });
      }
      setTimeout(() => {
        // fit the svg on the screen
        if (refMm.current) refMm.current.fit();

        // make all links open in new tab
        const allLinks = refSvg.current?.querySelectorAll("a");
        if (allLinks) {
          allLinks.forEach((link) => {
            link.setAttribute("target", "_blank"); // Open in new tab
            link.setAttribute("rel", "noopener noreferrer"); // Security best practice
          });
        }
      }, 50);
    };

    updateSvg();

    const observer = new MutationObserver(() => {
      updateSvg();
    });

    if (refSvg.current) {
      observer.observe(refSvg.current, {
        childList: true,
        subtree: true,
      });
    }

    // open the markmap example page on new tab instead of same:
    if (refToolbar.current) {
      setTimeout(() => {
        // Find the Markmap example button
        const exampleButton = refToolbar.current.querySelector(
          'a[href="https://markmap.js.org/"]'
        );
        if (exampleButton) {
          exampleButton.setAttribute("target", "_blank"); // Open in new tab
          exampleButton.setAttribute("rel", "noopener noreferrer"); // Security best practice
        }
      }, 100); // Short delay to ensure the toolbar is fully rendered
    }

    const handleResize = () => {
      if (refMm.current && markmapContainerRef.current) {
        refMm.current.fit();
      }
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        // Most browsers will display a generic message and ignore this custom text.
        event.preventDefault();
        event.returnValue =
          "Oops we didn't get to save your changes yet. Are you sure you want to leave?";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  useEffect(() => {
    if (!creating && refMm.current) {
      refMm.current.fit();
    }
  }, [creating]);

  useEffect(() => {
    if (refMm.current) {
      refMm.current.fit();
    }
  }, [editorOpen]);

  useEffect(() => {
    if (markmapStatus === ApiStatus.Fulfilled) {
      setCreating(false);
    }
    if (openBook?.markmap || markmapStatus === ApiStatus.Fulfilled) {
      setMarkmap(openBook?.markmap);
    } else if (!openBook?.markmap && !openBook?.read_only) {
      setMarkmap(exampleMarkmap);
    }
  }, [markmapStatus, openBook]);

  const handleCreateMarkmap = () => {
    if (userData.isAdmin) {
      handleCreate();
    } else {
      setCreateDialogOpen(true);
    }
  };

  const handleDialogClose = () => {
    setCreateDialogOpen(false);
  };

  const handleCreate = () => {
    if (openBook?._id) {
      setJustCreated(true);
      setCreating(true);
      if (userData.isAdmin) {
        dispatch(createMarkmapAdmin(openBook._id));
      } else {
        dispatch(createMarkmap(openBook._id));
      }
    }
    handleDialogClose();
  };

  // If the markmap was just created, open the editor by default.
  useEffect(() => {
    if (justCreated) {
      setEditorOpen(true);
    }
  }, [justCreated]);

  // Create markmap and save to refMm once refSvg is available.
  useEffect(() => {
    if (refMm.current) return;
    const options = isMdUp
      ? {
          initialExpandLevel: -1,
        }
      : {
          initialExpandLevel: 2,
        };
    const mm = Markmap.create(refSvg.current, options);
    refMm.current = mm;
    renderToolbar(refMm.current, refToolbar.current);
    setTimeout(() => {
      const exampleButton = refToolbar.current?.querySelector(
        'a[href="https://markmap.js.org/"]'
      );
      if (exampleButton) {
        exampleButton.setAttribute("target", "_blank"); // Open in new tab
        exampleButton.setAttribute("rel", "noopener noreferrer"); // Security best practice
      }
    }, 500);
  }, [refSvg, isMdUp]);

  useEffect(() => {
    if (markmap && typeof markmap === "string") {
      const mm = refMm.current;
      if (!mm) return;
      const { root } = transformer.transform(markmap);
      mm.setData(root);
      mm.fit();
    }
  }, [markmap]);

  const handleChange = (e) => {
    setMarkmap(e.target.value);
    setHasUnsavedChanges(true);
  };

  const handleSave = (e) => {
    if (hasUnsavedChanges && openBook?._id) {
      dispatch(
        updateBook({
          bookId: openBook._id,
          newData: { markmap: e.target.value },
        })
      );
      setHasUnsavedChanges(false);
    }
  };

  const toggleEditor = () => {
    setEditorOpen((prev) => !prev);
  };

  return (
    <div className="flex flex-col h-screen relative mapViewWrapper">
      {!openBook && (
        <Message
          message="Oops! You have not selected a book yet!"
          instruction="select a book first, please."
          heightVh="15"
        />
      )}
      {creating && (
        <p className="primaryColor">
          We are creating your markmap. It will take one minute or so, thank you
          for your patience!
        </p>
      )}
      {markmapStatus === ApiStatus.Rejected && (
        <p className="alert">Error creating markmap, please try again later.</p>
      )}

      {canCreateMarkmap && !creating && (
        <>
          <PrimaryButton
            onClick={handleCreateMarkmap}
            buttonClass="fullWidthTan"
          >
            {openBook?.markmap?.length ? "Recreate" : "Create"} markmap for{" "}
            <i>{openBook?.title}</i>
          </PrimaryButton>

          <Dialog open={createDialogOpen} onClose={handleDialogClose}>
            <DialogContent>
              <DialogContentText>
                {(userData.profile.paymentStatus === paymentStatuses.Paid ||
                  userData.profile.paymentStatus === paymentStatuses.Trial) && (
                  <>
                    You can only create one markmap per book and you get best
                    results when the highlights have been fully synced. <br />
                    <br />
                    You can go ahead an proceed with the creation or sync the
                    highligts first. <br />
                    <br /> We’re excited to share that in upcoming versions,
                    you'll have the option to purchase extra tokens for even
                    more access. Stay tuned for updates!
                  </>
                )}
                {userData.profile.paymentStatus === paymentStatuses.Free && (
                  <>
                    On your free subscription, you only have one try at using
                    this feature. <br />
                    Once you use it, you can upgrade to paid subscription to be
                    able to create a markmap for any book in your library.
                    <br />
                    <br /> Make sure this is the book you want to try this
                    feature on and that your highlights are up to date for best
                    results. <br />
                    <br />
                    You can go ahead an proceed with the creation or sync the
                    highligts first.
                    <br />
                    <br />
                  </>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <PrimaryButton
                onClick={handleCreate}
                buttonClass="backgroundGreyNoBorder flat"
              >
                Create
              </PrimaryButton>

              <AmazonSingleSync
                bookData={openBook}
                originView={SyncOrigin.Book}
              />

              <PrimaryButton
                onClick={handleDialogClose}
                buttonClass="backgroundGreyNoBorder flat"
              >
                Cancel
              </PrimaryButton>
            </DialogActions>
          </Dialog>
        </>
      )}

      {openBook && !openBook?.read_only && (
        <PrimaryButton onClick={toggleEditor} buttonClass="fullWidthTan">
          {editorOpen ? "Hide Markdown Editor" : "Show Markdown Editor"}
        </PrimaryButton>
      )}

      {editorOpen && !openBook?.read_only && (
        <div className="p-2">
          <textarea
            rows={10}
            className="w-full border border-gray-400 p-2 textPrimary cardBackground"
            style={{ resize: "vertical" }}
            value={markmap}
            onChange={handleChange}
            onBlur={handleSave}
          />
        </div>
      )}

      <div className="flex-grow relative" ref={markmapContainerRef}>
        <svg
          className="w-full h-full"
          ref={refSvg}
          style={{
            display: "block",
          }}
        />
      </div>

      <div className="absolute bottom-1 right-1" ref={refToolbar}></div>
    </div>
  );
}
