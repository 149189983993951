import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import axios from "axios";
import { setLibraryFilter } from "./userConfigSlice";
import { putProfileData } from "./profileSlice";
import { ApiStatus } from "./ApiStatus";
import {
  getApiUrl,
  getNumericEnvVariable,
} from "../../helperFunctions/envVars";
import { getHeaders } from "./authSlice";
import { like as likeSuggestion } from "./suggestionSlice";

const apiUrl = getApiUrl();
const apiRoot = apiUrl + "/api";
export const fetchLibrary = createAsyncThunk(
  "library/fetchBooks",
  async (dummy, thunkAPI) => {
    const page = thunkAPI.getState().library.page;
    const filter = thunkAPI.getState().userConfig.libraryFilter;
    const pageLimit = getNumericEnvVariable("REACT_APP_LIBRARY_PAGE_SIZE", 20);
    const response = await axios.post(
      `${apiRoot}/library/fetch/progress/filter?limit=${pageLimit}&page=${page}`,
      filter,
      getHeaders()
    );
    return {
      library: response.data.data,
      pagination: response.data.pagination,
    };
  }
);

export const selectBook = createAsyncThunk(
  "library/selectBook",
  async (bookId, thunkAPI) => {
    let bookData = await getBookById(thunkAPI.getState(), bookId);
    if (!bookData) {
      bookData = await fetchSingleBook(bookId, thunkAPI);
      if (bookData?.length > 0) {
        bookData = bookData[0];
      }
    }
    if (bookData?._id) {
      // we can select followed books so marking them viewed will not work
      // TODO: should we check if book is public and use selectPublicBook instead (in the library list?)
      const currentUserId = thunkAPI.getState().auth.userId;
      if (bookData.user_id === currentUserId) {
        markBookAsViewed(bookId, thunkAPI);
      }
      return {
        bookId,
        bookData,
      };
    } else {
      thunkAPI.reject(
        "Book " + bookId + " could not be selected, data not found."
      );
    }
  }
);

export const fetchBook = createAsyncThunk(
  "library/fetchBook",
  async (bookId) => {
    return fetchSingleBook(bookId);
  }
);

const fetchSingleBook = async (bookId) => {
  const response = await axios.get(
    `${apiRoot}/library/fetch/progress?_id=${bookId}`,
    getHeaders()
  );
  return response.data.data;
};

export const selectPublicBook = createAsyncThunk(
  "library/selectPublicBook",
  async ({ bookId }) => {
    const response = await axios.get(
      `${apiRoot}/library/fetch/public?_id=${bookId}`,
      getHeaders()
    );
    return response.data.data[0];
  }
);

export const follow = createAsyncThunk("library/follow", async (bookId) => {
  const response = await axios.put(
    `${apiRoot}/library/follow?book_id=${bookId}`,
    {},
    getHeaders()
  );
  return {
    bookId,
    updateData: response.data.data,
  };
});

export const unfollow = createAsyncThunk("library/unfollow", async (bookId) => {
  const response = await axios.put(
    `${apiRoot}/library/unfollow?book_id=${bookId}`,
    {},
    getHeaders()
  );
  return {
    bookId,
    updateData: response.data.data,
  };
});

export const fetchBookList = createAsyncThunk(
  "library/fetchBookList",
  async () => {
    const response = await axios.get(
      `${apiRoot}/library/fetch/booklist?sort=seen_at>desc&exclude_type=EBOOK_SAMPLE`,
      getHeaders()
    );
    return response.data.data;
  }
);

export const fetchListViewData = createAsyncThunk(
  "library/fetchListViewData",

  async (bookId) => {
    const response = await axios.get(
      `${apiRoot}/content/highlights?book_id=${bookId}`,
      getHeaders()
    );
    const responseIdeas = await axios.get(
      `${apiRoot}/ideas/fetch/book?book_id=${bookId}`,
      getHeaders()
    );
    return {
      highlights: response.data.data[0],
      ideas: responseIdeas.data.data,
    };
  }
);

export const updateBook = createAsyncThunk(
  "library/updateBook",
  async ({ bookId, newData }) => {
    await axios.put(
      `${apiRoot}/library/update?_id=${bookId}`,
      newData,
      getHeaders()
    );

    // book updated_at field is updated at backend, so to keep up:
    newData.updated_at = Date.now();

    return { bookId, newData };
  }
);

export const updateBookPrivacy = createAsyncThunk(
  "library/updatePrivacy",
  async ({ bookId, isPublic }) => {
    const newData = { public: isPublic };
    await axios.put(
      `${apiRoot}/library/update/privacy?_id=${bookId}`,
      newData,
      getHeaders()
    );

    // book updated_at field is updated at backend, so to keep up:
    newData.updated_at = Date.now();

    return { bookId, newData };
  }
);

export const deleteBook = createAsyncThunk(
  "library/deleteBook",
  async ({ bookId }) => {
    await axios.delete(`${apiRoot}/library/delete?_id=${bookId}`, getHeaders());

    // book deleted_at field is updated at backend, so to keep up:
    const newData = { deleted_at: Date.now() };

    return { bookId, newData };
  }
);

export const restoreDeletedBook = createAsyncThunk(
  "library/restoreDeletedBook",
  async ({ bookId }) => {
    await axios.put(
      `${apiRoot}/library/restore?_id=${bookId}`,
      {},
      getHeaders()
    );

    // book deleted_at field is updated at backend, so to keep up:
    const newData = { deleted_at: null };

    return { bookId, newData };
  }
);

const markBookAsViewed = async (bookId) => {
  await axios.put(`${apiRoot}/library/view?_id=${bookId}`, {}, getHeaders());

  return { bookId };
};

export const viewBook = createAsyncThunk("library/viewBook", async (bookId) => {
  return markBookAsViewed(bookId);
});

export const updateIdeaCard = createAsyncThunk(
  "library/updateIdeaCard",
  async ({ ideaCardId, newData }) => {
    await axios.put(
      `${apiRoot}/ideas/update?_id=${ideaCardId}`,
      newData,
      getHeaders()
    );
    return { ideaCardId, newData };
  }
);
export const updateIdeaCardLabel = createAsyncThunk(
  "library/updateIdeaCardLabel",
  async ({ ideaCardId, newData }) => {
    await axios.put(
      `${apiRoot}/ideas/update_label_type?_id=${ideaCardId}`,
      newData,
      getHeaders()
    );
    return { ideaCardId, newData };
  }
);

export const addIdeaCard = createAsyncThunk(
  "library/addIdeaCard",
  async ({ data }) => {
    const response = await axios.post(
      `${apiRoot}/ideas/store`,
      data,
      getHeaders()
    );

    return {
      newId: response.data.data,
      data: data,
    };
  }
);

// TODO: test after backend changed
export const deleteIdeaCard = createAsyncThunk(
  "library/deleteIdeaCard",
  async ({ ideaCardId }) => {
    await axios.delete(
      `${apiRoot}/ideas/delete/one?_id=${ideaCardId}`,
      getHeaders()
    );

    return ideaCardId;
  }
);

export const updateIdeaCardRelation = createAsyncThunk(
  "library/updateIdeaCardRelation",
  async ({ ideaCardId, newData }) => {
    await axios.put(
      `${apiRoot}/ideas/update/idea-relation?_id=${ideaCardId}`,
      newData,
      getHeaders()
    );
    return { ideaCardId, newData };
  }
);

export const updateLinkedHighlights = createAsyncThunk(
  "library/updateLinkedHighlights",
  async ({ ideaCardId, newData }) => {
    await axios.put(
      `${apiRoot}/ideas/update/linked-highlights?_id=${ideaCardId}`,
      newData,
      getHeaders()
    );
    return { ideaCardId, newData };
  }
);

// fetching tags based on filter type
export const fetchTags = async (type) => {
  const response = await axios.get(
    `${apiRoot}/tag/list?filter=${type}`,
    getHeaders()
  );
  return response.data.data;
};

export const fetchBookTags = createAsyncThunk(
  "library/fetchBookTags",
  async () => {
    return fetchTags("book");
  }
);

export const fetchChapterTags = createAsyncThunk(
  "library/fetchChapterTags",
  async () => {
    return fetchTags("content");
  }
);

export const fetchIdeaCardTags = createAsyncThunk(
  "library/fetchIdeaCardTags",
  async () => {
    return fetchTags("idea");
  }
);

export const fetchAllTags = createAsyncThunk(
  "library/fetchAllTags",
  async () => {
    return fetchTags("book,content,idea");
  }
);

export const updateChapterTags = createAsyncThunk(
  "library/updateChapterTags",
  async ({ contentId, newData }) => {
    await axios.put(
      `${apiRoot}/content/tags?_id=${contentId}`,
      newData,
      getHeaders()
    );
    return { contentId, newData };
  }
);

export const createMarkmap = createAsyncThunk(
  "library/markmap",
  async (bookId) => {
    const result = await axios.get(
      `${apiRoot}/library/markmap?_id=${bookId}`,
      getHeaders()
    );
    return { bookId, newData: result?.data?.data };
  }
);

export const createMarkmapAdmin = createAsyncThunk(
  "library/markmap-admin",
  async (bookId, thunkAPI) => {
    const userData = thunkAPI.getState().auth.userData;
    if (userData.isAdmin) {
      const result = await axios.get(
        `${apiRoot}/library/markmap-admin?_id=${bookId}`,
        getHeaders()
      );
      return { bookId, newData: result?.data?.data };
    } else {
      return thunkAPI.rejectWithValue("Unauthorized");
    }
  }
);

export const librarySlice = createSlice({
  name: "librarySlice",
  initialState: {
    libraryData: [],
    libraryStatus: null,
    libraryError: null,
    currentBookId: null,
    currentBook: null,
    currentIdeaCardId: null,
    listViewData: null,
    viewStatus: null,
    viewError: null,
    ideaCards: null,
    allTags: [],
    bookTags: [],
    chapterTags: [],
    ideaCardTags: [],
    allTagsStatus: ApiStatus.NotRun,
    bookTagsStatus: ApiStatus.NotRun,
    chapterTagsStatus: ApiStatus.NotRun,
    ideaCardTagsStatus: ApiStatus.NotRun,
    paginationInfo: null,
    page: 1,
    filter: null,
    bookList: [],
    bookListStatus: ApiStatus.NotRun,
    scrollToCurrentBookStateOn: false,
    markmapStatus: ApiStatus.NotRun,
  },
  reducers: {
    reset: (state) => {
      state.libraryData = [];
      state.libraryStatus = null;
      state.libraryError = null;
      state.currentBookId = null;
      state.currentBook = null;
      state.currentIdeaCardId = null;
      state.listViewData = null;
      state.viewStatus = null;
      state.viewError = null;
      state.ideaCards = null;
      state.allTags = [];
      state.bookTags = [];
      state.chapterTags = [];
      state.ideaCardTags = [];
      state.allTagsStatus = ApiStatus.NotRun;
      state.bookTagsStatus = ApiStatus.NotRun;
      state.chapterTagsStatus = ApiStatus.NotRun;
      state.ideaCardTagsStatus = ApiStatus.NotRun;
      state.paginationInfo = null;
      state.page = 1;
      state.filter = null;
      state.bookList = [];
      state.bookListStatus = null;
      state.scrollToCurrentBookStateOn = false;
      state.markmapStatus = ApiStatus.NotRun;
    },
    resetLibrary: (state) => {
      state.page = 1;
      state.libraryData = [];
      state.libraryStatus = ApiStatus.NotRun;
      state.currentBookId = null;
      state.currentBook = null;
    },
    resetViewData: (state) => {
      state.currentIdeaCardId = null;
      state.listViewData = null;
      state.viewStatus = null;
      state.viewError = null;
      state.ideaCards = null;
    },
    scrollStateOff: (state, action) => {
      state.scrollToCurrentBookStateOn = false;
    },
    selectIdeaCard: (state, action) => {
      state.currentIdeaCardId = action.payload;
    },
    incrementPage: (state, action) => {
      state.page++;
    },
  },
  extraReducers(builder) {
    builder
      // new library filter is set in userConfigSlice => reset library data and page values
      .addCase(setLibraryFilter, (state) => {
        state.libraryData = [];
        state.page = 1;
      })
      // in case profile data is updated check if public field was changed and then empty library data & current book to be refetched
      // book isn't refetched automatically, so book view will show the Please select book / no book selected.
      // TODO: consider if worth adding the logic updating it / whole libray to frontend also
      .addCase(putProfileData.fulfilled, (state, action) => {
        if (
          action.payload.public !== null &&
          action.payload.public !== undefined
        ) {
          state.libraryData = [];
          state.currentBook = null;
          state.currentBookId = null;
        }
      })
      .addCase(fetchLibrary.pending, (state, action) => {
        state.libraryStatus = ApiStatus.Pending;
        state.libraryError = null;
        if (!state.libraryData) state.libraryData = null;
      })
      .addCase(fetchLibrary.fulfilled, (state, action) => {
        if (state.page > 1) {
          state.libraryData = [...state.libraryData, ...action.payload.library];
        } else {
          state.libraryData = action.payload.library;
        }
        state.paginationInfo = action.payload.pagination;
        state.libraryStatus = ApiStatus.Fulfilled;
      })
      .addCase(fetchLibrary.rejected, (state, action) => {
        state.libraryStatus = ApiStatus.Rejected;
        state.libraryError = action.error.message;
      })
      .addCase(fetchBook.pending, (state, action) => {})
      .addCase(fetchBook.fulfilled, (state, action) => {
        const book = action.payload[0];
        if (book) {
          const bookIndex = getBookIndexById(state, book._id);
          if (bookIndex !== null) {
            state.libraryData[bookIndex] = book;
          }
          if (state.currentBookId === book._id) {
            state.currentBook = book;
          }
        }
      })
      .addCase(selectBook.fulfilled, (state, action) => {
        state.currentBookId = action.payload.bookId;
        state.currentBook = action.payload.bookData;
        state.scrollToCurrentBookStateOn = true;
        state.currentIdeaCardId = null;
        state.listViewData = null;
        state.ideaCards = null;
        state.markmapStatus = ApiStatus.NotRun;
      })
      .addCase(selectPublicBook.fulfilled, (state, action) => {
        state.currentBookId = action.payload._id;
        state.currentBook = action.payload;
        state.currentIdeaCardId = null;
        state.listViewData = null;
        state.ideaCards = null;
        state.markmapStatus = ApiStatus.NotRun;
      })
      .addCase(follow.fulfilled, (state, action) => {
        updateSliceBook(
          state,
          action.payload.bookId,
          action.payload.updateData
        );
      })
      .addCase(unfollow.fulfilled, (state, action) => {
        updateSliceBook(
          state,
          action.payload.bookId,
          action.payload.updateData
        );
      })
      .addCase(fetchBookList.pending, (state, action) => {
        state.bookListStatus = ApiStatus.Pending;
      })
      .addCase(fetchBookList.fulfilled, (state, action) => {
        state.bookList = action.payload;
        state.bookListStatus = ApiStatus.Fulfilled;
      })
      .addCase(fetchBookList.rejected, (state, action) => {
        state.bookListStatus = ApiStatus.Fulfilled;
      })
      .addCase(updateBook.fulfilled, (state, action) => {
        updateSliceBook(state, action.payload.bookId, action.payload.newData);

        if (action.payload.newData.tags?.length > 0) {
          updateBookTagsList(state, action.payload.newData.tags);
        }
      })
      .addCase(updateBookPrivacy.fulfilled, (state, action) => {
        updateSliceBook(state, action.payload.bookId, action.payload.newData);
      })
      .addCase(deleteBook.fulfilled, (state, action) => {
        updateSliceBook(state, action.payload.bookId, action.payload.newData);

        state.bookList = state.bookList.filter(
          (book) => book._id !== action.payload.bookId
        );
      })
      .addCase(restoreDeletedBook.fulfilled, (state, action) => {
        updateSliceBook(state, action.payload.bookId, action.payload.newData);
        // add book back to booklist:
        // only works if book is in slice, if we add restore option elsewhere need to refetch the whole list
        const bookIndex = getBookIndexById(state, action.payload.bookId);
        if (bookIndex > -1) {
          state.bookList = [...state.bookList, state.libraryData[bookIndex]];
          // lets even resort by title
          state.bookList.sort(function (book1, book2) {
            var title1 = book1.title.toLowerCase();
            var title2 = book2.title.toLowerCase();
            return title1 < title2 ? -1 : title1 > title2 ? 1 : 0;
          });
        }
      })
      .addCase(viewBook.fulfilled, (state, action) => {
        const bookIndex = getBookIndexById(state, action.payload.bookId);

        if (state.libraryData[bookIndex] && bookIndex >= 0) {
          state.libraryData[bookIndex] = {
            ...state.libraryData[bookIndex],
            ...{ seen_at: Date.now() },
          };
        }
      })
      .addCase(fetchListViewData.pending, (state, action) => {
        state.viewStatus = ApiStatus.Pending;
        state.viewError = null;
        state.listViewData = null;
        state.ideaCards = null;
      })
      .addCase(fetchListViewData.fulfilled, (state, action) => {
        state.listViewData = action.payload.highlights;
        state.ideaCards = action.payload.ideas;
        state.viewStatus = ApiStatus.Fulfilled;
      })
      .addCase(fetchListViewData.rejected, (state, action) => {
        state.viewStatus = ApiStatus.Rejected;
        state.viewError = action.error.message;
      })
      .addCase(updateIdeaCard.fulfilled, (state, action) => {
        const ideaCardIndex = getIdeaCardIndexById(
          state,
          action.payload.ideaCardId
        );
        const ideaCard = getIdeaCardById(state, action.payload.ideaCardId);

        if (ideaCard) {
          state.ideaCards[ideaCardIndex] = {
            ...ideaCard,
            ...action.payload.newData,
          };
          // book updated_at field is updated at backend when idea cards are changed, so to keep up:
          markIdeaBookUpdated(state, ideaCard);
        }
        if (action.payload.newData.tags) {
          updateIdeaCardTagsList(state, action.payload.newData.tags);
        }
      })
      .addCase(updateIdeaCardLabel.fulfilled, (state, action) => {
        const ideaCardIndex = getIdeaCardIndexById(
          state,
          action.payload.ideaCardId
        );
        const ideaCard = getIdeaCardById(state, action.payload.ideaCardId);

        if (ideaCard) {
          state.ideaCards[ideaCardIndex] = {
            ...ideaCard,
            ...action.payload.newData,
          };
          // book updated_at field is updated at backend when idea cards are changed, so to keep up:
          markIdeaBookUpdated(state, ideaCard);
        }
      })
      .addCase(addIdeaCard.fulfilled, (state, action) => {
        if (action.payload.newId) {
          const addIdeaCardToListViewData = (item, newCard) => {
            item.entries?.forEach((entry, i) => {
              addIdeaCardToListViewData(entry, newCard);
            });
            item.highlights?.forEach((highlight, i) => {
              if (highlight._id === newCard.highlight_id) {
                highlight.idea_cards.push(newCard);
              }
            });
          };

          let newData = { ...action.payload.data };
          newData._id = action.payload.newId;

          if (!state.ideaCards) {
            state.ideaCards = [];
          }
          state.ideaCards.push(newData);
          state.currentIdeaCardId = action.payload.newId;

          if (state.listViewData) {
            state.listViewData.data.forEach((entry, i) => {
              addIdeaCardToListViewData(entry, newData);
            });
          }

          updateIdeaCardCountForBook(state, newData.book_id, 1);
        }
      })
      .addCase(addIdeaCard.rejected, (state, action) => {
        console.log("error adding ideacard", action.error);
        state.currentIdeaCardId = null;
      })

      .addCase(deleteIdeaCard.fulfilled, (state, action) => {
        const deleteIdeaCardFromListViewData = (item, deletedId) => {
          item.entries?.forEach((entry, i) => {
            deleteIdeaCardFromListViewData(entry, deletedId);
          });
          item.highlights?.forEach((highlight, i) => {
            const ideaCardToRemoveIndex = highlight.idea_cards.findIndex(
              (ideacard) => ideacard._id === deletedId
            );
            if (ideaCardToRemoveIndex > -1) {
              highlight.idea_cards.splice(ideaCardToRemoveIndex, 1);
            }
          });
        };

        const deletedId = action.payload;

        const ideaCard = getIdeaCardById(state, deletedId);
        const bookId = ideaCard.book_id;

        state.ideaCards = state.ideaCards.filter((ideacard) => {
          return ideacard._id !== deletedId;
        });

        if (state.listViewData) {
          state.listViewData.data.forEach((entry, i) => {
            deleteIdeaCardFromListViewData(entry, deletedId);
          });
        }
        state.currentIdeaCardId = null;

        updateIdeaCardCountForBook(state, bookId, -1);
      })
      .addCase(deleteIdeaCard.rejected, (state, action) => {
        console.log("error removing ideacard", action.error);
      })
      .addCase(updateIdeaCardRelation.fulfilled, (state, action) => {
        const ideaCardIndex = getIdeaCardIndexById(
          state,
          action.payload.ideaCardId
        );
        const ideaCard = getIdeaCardById(state, action.payload.ideaCardId);

        if (ideaCard) {
          state.ideaCards[ideaCardIndex] = {
            ...ideaCard,
            ...action.payload.newData,
          };
          // book updated_at field is updated at backend when idea cards are changed, so to keep up:
          markIdeaBookUpdated(state, ideaCard);
        }
      })
      .addCase(updateLinkedHighlights.fulfilled, (state, action) => {
        const ideaCardIndex = getIdeaCardIndexById(
          state,
          action.payload.ideaCardId
        );
        const ideaCard = getIdeaCardById(state, action.payload.ideaCardId);

        if (ideaCard) {
          state.ideaCards[ideaCardIndex] = {
            ...ideaCard,
            ...action.payload.newData,
          };
          // book updated_at field is updated at backend when idea cards are changed, so to keep up:
          markIdeaBookUpdated(state, ideaCard);
        }
      })
      // fetch tags
      .addCase(fetchAllTags.pending, (state, action) => {
        state.allTagsStatus = ApiStatus.Pending;
      })
      .addCase(fetchAllTags.fulfilled, (state, action) => {
        state.allTagsStatus = ApiStatus.Fulfilled;
        const tags = action.payload;
        if (tags) {
          state.allTags = tags;
        }
      })
      .addCase(fetchAllTags.rejected, (state, action) => {
        state.allTagsStatus = ApiStatus.Rejected;
        console.log("fetch All Tags failed", action.error.message);
      })
      .addCase(fetchBookTags.pending, (state, action) => {
        state.bookTagsStatus = ApiStatus.Pending;
      })
      .addCase(fetchBookTags.fulfilled, (state, action) => {
        state.bookTagsStatus = ApiStatus.Fulfilled;
        const tags = action.payload;
        if (tags) {
          state.bookTags = tags;
        }
      })
      .addCase(fetchBookTags.rejected, (state, action) => {
        state.bookTagsStatus = ApiStatus.Rejected;
        console.log("fetch Book Tags failed", action.error.message);
      })
      .addCase(fetchChapterTags.pending, (state, action) => {
        state.chapterTagsStatus = ApiStatus.Pending;
      })
      .addCase(fetchChapterTags.fulfilled, (state, action) => {
        state.chapterTagsStatus = ApiStatus.Fulfilled;
        const tags = action.payload;
        if (tags) {
          state.chapterTags = tags;
        }
      })
      .addCase(fetchChapterTags.rejected, (state, action) => {
        state.chapterTagsStatus = ApiStatus.Rejected;
        console.log("fetch Chapter Tags failed", action.error.message);
      })
      .addCase(fetchIdeaCardTags.pending, (state, action) => {
        state.ideaCardTagsStatus = ApiStatus.Pending;
      })
      .addCase(fetchIdeaCardTags.fulfilled, (state, action) => {
        state.ideaCardTagsStatus = ApiStatus.Fulfilled;
        const tags = action.payload;
        if (tags) {
          state.ideaCardTags = tags;
        }
      })
      .addCase(fetchIdeaCardTags.rejected, (state, action) => {
        state.ideaCardTagsStatus = ApiStatus.Rejected;
        console.log("fetch Idea Card Tags failed", action.error.message);
      })
      .addCase(updateChapterTags.fulfilled, (state, action) => {
        const addChapterTagsToListViewData = (tags, updatedTags) => {
          let existingChapterTagsUpdated = false;
          tags?.forEach((chapterTags) => {
            if (chapterTags.label === updatedTags.label) {
              chapterTags.tags = updatedTags.tags;
              existingChapterTagsUpdated = true;
            }
          });
          if (!existingChapterTagsUpdated) {
            tags?.push(updatedTags);
          }
        };

        updateChapterTagsList(state, action.payload.newData.tags);

        if (state.listViewData) {
          addChapterTagsToListViewData(
            state.listViewData.tags,
            action.payload.newData
          );
        }
      })
      .addCase(updateChapterTags.rejected, (state, action) => {
        console.log("updating tags failed", action.error.message);
      })
      .addCase(likeSuggestion.fulfilled, (state, action) => {
        if (action.payload.ideaCreated === state.currentBookId) {
          state.listViewData = null;
        }
      })
      .addMatcher(
        isAnyOf(createMarkmap.pending, createMarkmapAdmin.pending),
        (state, action) => {
          state.markmapStatus = ApiStatus.Loading;
        }
      )
      .addMatcher(
        isAnyOf(createMarkmap.fulfilled, createMarkmapAdmin.fulfilled),
        (state, action) => {
          updateSliceBook(
            state,
            action.payload.bookId,
            action.payload.newData.book
          );
          state.markmapStatus = ApiStatus.Fulfilled;
        }
      )
      .addMatcher(
        isAnyOf(createMarkmap.rejected, createMarkmapAdmin.rejected),
        (state, action) => {
          state.markmapStatus = ApiStatus.Rejected;
        }
      );
  },
});

export const {
  scrollStateOff,
  selectIdeaCard,
  reset,
  resetLibrary,
  resetViewData,
  incrementPage,
} = librarySlice.actions;
export default librarySlice.reducer;

const updateSliceBook = (state, bookId, newData) => {
  const bookIndex = getBookIndexById(state, bookId);

  if (state.libraryData[bookIndex]) {
    state.libraryData[bookIndex] = {
      ...state.libraryData[bookIndex],
      ...newData,
    };
  }

  if (state.currentBookId === bookId) {
    state.currentBook = {
      ...state.currentBook,
      ...newData,
    };
  }
};

const updateIdeaCardCountForBook = (state, bookId, changeAmount) => {
  const book = getBookById(state, bookId);

  if (book) {
    const currentBookIndex = getBookIndexById(state, state.currentBookId);
    // only if book is currently in slice, which it should be since we are editing
    // but better safe than sorry
    if (currentBookIndex >= 0) {
      state.libraryData[currentBookIndex] = {
        ...book,
        i_progress: book.i_progress + changeAmount,
        // book updated_at field is updated at backend when idea cards are added or deleted, so to keep up:
        updated_at: Date.now(),
      };
    }
  }
};

const markIdeaBookUpdated = (state, ideaCard) => {
  const bookId = ideaCard?.book_id;

  if (bookId) {
    const book = getBookById(state, bookId);
    if (book) book.updated_at = Date.now();
  }
};

const updateTagsList = (tagsList, newTags) => {
  if (tagsList?.length === 0) {
    return newTags;
  } else {
    const tagsToAdd = [];
    newTags.forEach((newTag) => {
      const matchingCount = tagsList.filter(
        (oldTag) => oldTag === newTag
      ).length;
      if (matchingCount === 0) {
        tagsToAdd.push(newTag);
      }
    });
    if (tagsToAdd.length > 0) {
      return [...tagsList, ...tagsToAdd].sort();
    }
  }
  return tagsList;
};

const updateAllTagsList = (state, tags) => {
  state.allTagsTags = updateTagsList(state.allTags, tags);
};

const updateBookTagsList = (state, tags) => {
  state.bookTags = updateTagsList(state.bookTags, tags);
  updateAllTagsList(state, tags);
};

const updateChapterTagsList = (state, tags) => {
  state.chapterTags = updateTagsList(state.chapterTags, tags);
  updateAllTagsList(state, tags);
};

const updateIdeaCardTagsList = (state, tags) => {
  state.ideaCardTags = updateTagsList(state.ideaCardTags, tags);
  updateAllTagsList(state, tags);
};

export const getCurrentBook = (state) => {
  let book = state.library.currentBook;
  return book;
};
export const getCurrentIdeaCard = (state) => {
  return getIdeaCardById(state, state.library.currentIdeaCardId);
};
export const getCurrentIdeaCardId = (state) => {
  return state.library.currentIdeaCardId;
};

export const getBookIndexById = (state, bookId) => {
  const libraryData = state.libraryData
    ? state.libraryData
    : state.library.libraryData;
  const bookIndex = libraryData?.findIndex((book) => {
    return book._id === bookId;
  });
  return bookIndex;
};

export const getBookById = (state, bookId) => {
  if (!bookId) return null;

  const libraryData = state.libraryData
    ? state.libraryData
    : state.library.libraryData;

  const currentBookId = state.currentBookId
    ? state.currentBookId
    : state.library.currentBookId;

  if (bookId === currentBookId) {
    return state.currentBook ? state.currentBook : state.library.currentBook;
  }

  const bookIndex = getBookIndexById(state, bookId);
  if (bookIndex >= 0) {
    return libraryData[bookIndex];
  } else {
    return null;
  }
};

export const getBookName = (state, bookId) => {
  const book = state?.library?.bookList?.filter((book) => book._id === bookId);
  if (book && book[0] && book[0].title) {
    return book[0].title;
  } else {
    return "Book name unavailable at the moment. Apologies.";
  }
};

export const getIdeaCardIndexById = (state, ideaCardId) => {
  const ideaCards = state.ideaCards
    ? state.ideaCards
    : state.library?.ideaCards;
  const ideaCardIndex = ideaCards?.findIndex((ideaCard) => {
    return ideaCard._id === ideaCardId;
  });
  return ideaCardIndex;
};

export const getIdeaCardById = (state, ideaCardId) => {
  const ideaCardIndex = getIdeaCardIndexById(state, ideaCardId);
  const ideaCards = state.ideaCards
    ? state.ideaCards
    : state.library?.ideaCards;
  if (ideaCardIndex !== null && ideaCards) {
    return ideaCards[ideaCardIndex];
  } else {
    return null;
  }
};

export const getChapterByHighlightId = (state, highlightId) => {
  const findChapterWithHighlight = (item, highlightId) => {
    let matchingChapter = null;
    item.entries?.forEach((entry, i) => {
      const matchingChapterResult = findChapterWithHighlight(
        entry,
        highlightId
      );
      if (matchingChapterResult !== null) {
        matchingChapter = matchingChapterResult;
      }
    });
    if (matchingChapter !== null) {
      return matchingChapter;
    }
    item.highlights?.forEach((highlight, i) => {
      if (highlight._id === highlightId) {
        matchingChapter = item;
      }
    });
    return matchingChapter;
  };

  const content = state.listViewData
    ? state.listViewData
    : state.library?.listViewData;

  let foundChapterEntry = null;
  content?.data?.forEach((entry, i) => {
    let result = findChapterWithHighlight(entry, highlightId);
    if (result) foundChapterEntry = result;
  });
  return foundChapterEntry;
};
